<script setup lang="ts">

const {$activeModalsBus} = useNuxtApp();
const show = ref(false);
$activeModalsBus.listen('openPopupContact', () => {
  show.value = true;
});
$activeModalsBus.listen('closePopupContact', () => {
  show.value = false;
});


</script>

<template>
  <v-dialog v-model="show" :max-width="800" class="contact-mobile-modal">

        <a class="modal-close modal-close--absolute" @click="show = !show" href="javascript:void(0)" data-dismiss="modal" aria-label="Close">
          <nuxt-img src="/images/svg/close_w.svg" width="30" height="30" alt="אייקון של X לסימון סגירה חלון באתר"/>
        </a>
    <v-card color="transparent">

      <div class="modal-body ft-contact p-0">
        <div class="row ">
          <div class="col-md-6">
            <div class="inner_content text-center w-100">
              <span class="title-h2">נשמח לשמוע ממך</span>
              <div class="subtitle">נשאר רק לבחור איך הכי נוח לך לתקשר איתנו</div>
              <div class="icons row justify-content-md-center">
                <a class="icon-item col-md-3 col-6 mobile_only" href="tel:0555522553" target="_blank">
                  <div class="icon-warp relative">
                    <div class="icon"><img
                        src="https://admin.sasa.co.il/wp-content/themes/sasa/assets/images/icons/phone_hover.png"
                        alt="אייקון לסימון יצירת קשר דרך התקשרות טלפונית"></div>

                  </div>
                  <span class="subtitle-semibold font-weight-bold text-center red-underline red-underline--short2">לדבר
                                            בטלפון</span>
                </a>

                <a class="icon-item col-md-3 col-6 no_mobile" href="javascript:void(0)"
                   @click.prevent="$activeModalsBus.event('openPopup')">
                  <div class="icon-warp relative">
                    <div class="icon"><img
                        src="https://admin.sasa.co.il/wp-content/themes/sasa/assets/images/icons/phone_hover.png"
                        alt="אייקון לסימון יצירת קשר דרך התקשרות טלפונית"></div>

                  </div>
                  <span class="subtitle-semibold font-weight-bold text-center red-underline red-underline--short2">לדבר
                                            בטלפון</span>
                </a>
                <a class="icon-item col-md-3 col-6" href="https://wa.me/9720555522553" target="_blank">
                  <div class="icon-warp relative">
                    <img
                        src="https://admin.sasa.co.il/wp-content/themes/sasa/assets/images/icons/whatsapp_hover.png"
                        alt="וואטסאפ ציבעוני">
                  </div>
                  <span class="subtitle-semibold font-weight-bold text-center red-underline red-underline--short2">להסתמס
                                            בוואטסאפ</span>
                </a>
                <a class="icon-item col-md-3 col-6" href="https://t.me/sasa_shtihim" target="_blank">
                  <div class="icon-warp relative">
                    <img class="icon"
                         src="https://admin.sasa.co.il/wp-content/themes/sasa/assets/images/svg/telegram-hover.svg"
                         alt="אייקון טלגרם">

                  </div>
                  <span class="subtitle-semibold font-weight-bold text-center red-underline red-underline--short2">להתכתב
                                            בטלגרם</span>
                </a>
                <a class="icon-item col-md-3 col-6" href="javascript:void(0)"
                   @click.prevent="$activeModalsBus.event('openPopup')">
                  <div class="icon-warp relative">
                    <div class="icon"><img
                        src="https://admin.sasa.co.il/wp-content/themes/sasa/assets/images/icons/msg_hover.png"
                        alt="אייקון של מעטפה לסימון יצירת קשר דרך אימייל"></div>

                  </div>
                  <span class="subtitle-semibold font-weight-bold text-center red-underline red-underline--short2">לשלוח
                                            מייל</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.contact-mobile-modal{
  .modal-close{
    @media screen and (max-width: 768px){
      transform: translate(0,-150%);
    }
  }
}
</style>